import logo from './logo.svg';
import './App.css';
import {default as Pencil} from './pencil.svg'

import React, { useState, useRef, useEffect, useCallback } from "react";

const imageUrl = (
  imageId,
  variant = "public") =>
  `https://studio.renovateai.app/cdn-cgi/imagedelivery/OnVSK7GycIProf6V16E72A/${imageId}/${variant}`;


const BrushSlider = ({ value, onChange, max, min, enablePencil }) => {

  return (
    <label className="flex items-center space-x-2 mb-2" htmlFor="brush-size">
     {!enablePencil ? <span className="font-bold text-black">Brush Size: </span>
    :  <img src={Pencil} className="w-6 h-6" />}

      <input
        type="range"
        min={min}
        step={ enablePencil ? 0.1 : 1}
        className='mr-4'
        max={max}
        value={ value }
        onChange={ (e) => onChange( enablePencil ? parseFloat(e.target.value) : parseInt(e.target.value)) }
      />

      <span className="font-bold text-black">{ value }</span>
    </label>
  );
};

const UndoButton = ({ onClick }) => (
  <button className="font-bold text-black" onClick={ onClick }>🧹 Clear</button>
);

const ImageBrushing = ({ reverseMask, initialSketchType,isSketch }) => {
  const [sketchType, setSketchType] = useState(initialSketchType);
  const [base64Image, setImage] = useState("");
  const [brushSize, setBrushSize] = useState(
    isSketch ? 0.3 : 20
  );
  const [base64CanvasImage, setCanvasImage] = useState(base64Image);
  const didUserDrawRef = useRef(false);

  const canvas = useRef(null);
  const ctx = useRef(null);

  const drawingCanvas = useRef(null);
  const drawingCtx = useRef(null);
  const prevCoords = useRef({
    x: null,
    y: null,
  });
  const canvasOverlay = useRef(null);
  const ctxOverlay = useRef(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (canvasOverlay.current !== null) {
      ctxOverlay.current = canvasOverlay.current.getContext("2d");
    }
  }, []);

  useEffect(() => {
    if (base64Image) {
      setCanvasImage(base64Image);
    }
  }, [base64Image]);

  useEffect(() => {
    if (canvas.current) {
      ctx.current = canvas.current.getContext("2d");
    }
  }, []);

  useEffect(() => {
    if (drawingCanvas.current) {
      drawingCtx.current = drawingCanvas.current.getContext("2d");
    }
  }, []);


  useEffect(() => {
    if (ctx.current) {

      const img = new Image();
      window?.postMessage(JSON.stringify({ type: 'imageLoading', payload: {} }),);


      if(isSketch && !sketchType){
        return;
      }

      // if no image, and just sketching, then create a blank canvas
      if(sketchType === 'only_draw') {
        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight * 0.6;

        // Calculate the scale factors based on the screen dimensions
        const scaleFactorWidth = screenWidth / window.innerWidth * 0.9;
        const scaleFactorHeight = screenHeight / 300;

        // Choose the smaller scale factor to make sure that the image fits in both width and height
        const scaleFactor = Math.min(scaleFactorWidth, scaleFactorHeight);

        // Calculate the new dimensions for the canvas
        const newWidth = window.innerWidth * 0.9;
        const newHeight = window.innerWidth * 0.8;

        window?.postMessage(JSON.stringify({ type: 'imageLoaded', payload: { width: 300, height: 300 } }), '*');
        if (canvas.current) {
          canvas.current.width = newWidth;
          canvas.current.height = newHeight;

          // Update overlay canvas dimensions
          canvasOverlay.current.width = newWidth;
          canvasOverlay.current.height = newHeight;

          // Update drawing canvas dimensions
          drawingCanvas.current.width = newWidth;
          drawingCanvas.current.height = newHeight;
        }
        ctx.current.fillStyle = "white";
        // border dot line color and width of the canvas rounded
        canvas.current.style.border = "1px dashed #000";
        canvas.current.style.borderRadius = "5px";
        ctx.current.fillRect(0, 0, canvas.current.width, canvas.current.height);
        window?.postMessage(JSON.stringify({ type: 'imageLoaded', payload: { width: 300, height: 300 } }), '*');
        drawingCtx.current.clearRect(0, 0, canvas.current.width, canvas.current.height);
        setLoading(false);
        return;
      }


      img.src = base64CanvasImage;
      img.crossOrigin = "Anonymous";

      img.onload = () => {

        // Calculate scale factor and new width and height
        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight * 0.6;

        // Calculate the scale factors based on the screen dimensions
        const scaleFactorWidth = screenWidth / img.width;
        const scaleFactorHeight = screenHeight / img.height;

        // Choose the smaller scale factor to make sure that the image fits in both width and height
        const scaleFactor = Math.min(scaleFactorWidth, scaleFactorHeight);

        // Calculate the new dimensions for the canvas
        const newWidth = img.width * scaleFactor;
        const newHeight = img.height * scaleFactor;


        window?.postMessage(JSON.stringify({ type: 'imageLoaded', payload: { width: img.width, height: img.height } }), '*');
        if (canvas.current) {
          canvas.current.width = newWidth;
          canvas.current.height = newHeight;

          // Update overlay canvas dimensions
          canvasOverlay.current.width = newWidth;
          canvasOverlay.current.height = newHeight;

          // Update drawing canvas dimensions
          drawingCanvas.current.width = newWidth;
          drawingCanvas.current.height = newHeight;
        }
        ctx.current.globalAlpha = 1;
        ctx.current.drawImage(img, 0, 0, newWidth, newHeight);
        window?.postMessage(JSON.stringify({ type: 'imageLoaded', payload: { width: img.width, height: img.height } }), '*');
        drawingCtx.current.clearRect(0, 0, canvas.current.width, canvas.current.height);

        // set brush in the middle of the canvas as default
/*         const gridPattern = createGridPattern(brushSize);
        ctxOverlay.current.beginPath();
        ctxOverlay.current.arc(canvas.current.width / 2, canvas.current.height / 2, brushSize, 0, 2 * Math.PI);
        ctxOverlay.current.strokeStyle = "white";
        ctxOverlay.current.fillStyle = gridPattern;
        ctxOverlay.current.fill();
        ctxOverlay.current.lineWidth = 1.5;
        ctxOverlay.current.stroke(); */

        setLoading(false);
      };

      img.onerror = (err) => {
        window?.postMessage(JSON.stringify({ type: 'onerror', payload: JSON.stringify(err) }), '*');
      }
    }
  }, [base64CanvasImage, sketchType, isSketch]);

  const getTouchCoordinates = useCallback((e) => {
    const touch = e.touches[0];
    const rect = e.currentTarget.getBoundingClientRect();
    const x = touch.clientX - rect.left;
    const y = touch.clientY - rect.top;
    return { x, y };
  }, []);


  function createGridPattern(size) {
    const patternCanvas = document.createElement("canvas");
    patternCanvas.width = size * 2;
    patternCanvas.height = size * 2;
    const patternCtx = patternCanvas.getContext("2d");

    patternCtx.fillStyle = "#232326";
    patternCtx.fillRect(0, 0, size, size);
    patternCtx.fillRect(size, size, size, size);

    patternCtx.fillStyle = "#414143";
    patternCtx.fillRect(size, 0, size, size);
    patternCtx.fillRect(0, size, size, size);

    return patternCtx.createPattern(patternCanvas, "repeat");
  }

  const handleTouchMove = useCallback(
    (e) => {
      const { x, y } = getTouchCoordinates(e);

      if (!drawingCtx.current) return;

      let prevX = prevCoords.current.x;
      let prevY = prevCoords.current.y;

      const gridPattern = !isSketch ? createGridPattern(brushSize) : 'black';

      if (prevX !== null && prevY !== null) {
        if (ctxOverlay.current) {
          ctxOverlay.current.clearRect(0, 0, canvasOverlay.current.width, canvasOverlay.current.height);
          ctxOverlay.current.beginPath();
          ctxOverlay.current.moveTo(prevX, prevY);
          ctxOverlay.current.lineTo(x, y);
          ctxOverlay.current.lineWidth = 2;
          ctxOverlay.current.strokeStyle = "white";
          ctxOverlay.current.fillStyle = gridPattern
          ctxOverlay.current.lineCap = "round";
          ctxOverlay.current.lineJoin = "round";
          ctxOverlay.current.stroke();
        }
        drawingCtx.current.beginPath();
        drawingCtx.current.moveTo(prevX, prevY);
        drawingCtx.current.lineTo(x, y);
        drawingCtx.current.lineWidth = brushSize * 2;
        drawingCtx.current.strokeStyle = gridPattern;
        drawingCtx.current.lineCap = "round";
        drawingCtx.current.lineJoin = "round";
        drawingCtx.current.stroke();

        didUserDrawRef.current = true;
      }

      prevCoords.current.x = x;
      prevCoords.current.y = y;
    },
    [getTouchCoordinates, sketchType, brushSize]
  );

  const handleMouseMove = useCallback(
    (e) => {
      return;
      const rect = e.currentTarget.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;

      if (ctxOverlay.current) {
        ctxOverlay.current?.clearRect(0, 0, canvasOverlay.current.width, canvasOverlay.current.height);
        ctxOverlay.current.beginPath();
        ctxOverlay.current.arc(x, y, brushSize, 0, 2 * Math.PI);
        ctxOverlay.current.strokeStyle = "rgba(255, 255, 0, 0.5)";
        ctxOverlay.current.fillStyle = "rgba(255, 255, 0, 0.5)";
        ctxOverlay.current.fill();
        ctxOverlay.current.lineWidth = 2;
        ctxOverlay.current.stroke();
      }


      if (!drawingCtx.current || e.buttons !== 1) return;

      drawingCtx.current.beginPath();
      drawingCtx.current.arc(x, y, brushSize, 0, 2 * Math.PI);
      drawingCtx.current.fillStyle = "rgba(255, 255,0, 1)";
      drawingCtx.current.fill();
    },
    [brushSize]
  );

  const onClear = useCallback(() => {
    if (drawingCtx.current) {
      drawingCtx.current.clearRect(0, 0, canvas.current.width, canvas.current.height);
    }

    if (ctxOverlay.current) {
      ctxOverlay.current.clearRect(0, 0, canvasOverlay.current.width, canvasOverlay.current.height);
    }


    window?.postMessage(JSON.stringify({ type: 'onDrawMask', payload: undefined}), '*');
    prevCoords.current.x = null;
    prevCoords.current.y = null;

    didUserDrawRef.current = false;
  }, []);


  const generateResult = useCallback(() => {
    const resultCanvas = document.createElement("canvas");
    resultCanvas.width = canvas.current.width;
    resultCanvas.height = canvas.current.height;

    const resultCtx = resultCanvas.getContext("2d");

    // Draw the black background
    resultCtx.fillStyle = "black";
    resultCtx.fillRect(0, 0, resultCanvas.width, resultCanvas.height);

    // Draw the brush strokes in white
    resultCtx.globalCompositeOperation = "lighter";
    const imgData = drawingCtx.current.getImageData(0, 0, canvas.current.width, canvas.current.height);
    for (let i = 0; i < imgData.data.length; i += 4) {
      if (imgData.data[i + 3] > 0) {
        imgData.data[i] = 255;     // Set red channel
        imgData.data[i + 1] = 255; // Set green channel
        imgData.data[i + 2] = 255; // Set blue channel
      }
    }
    //resultCtx!.globalAlpha = 0.5; // Set the global alpha value for proper transparency


      // Invert the colors (black and white) of the data
      for (let i = 0; i < imgData.data.length; i += 4) {
        imgData.data[i] = 255 - imgData.data[i]; // Red channel
        imgData.data[i + 1] = 255 - imgData.data[i + 1]; // Green channel
        imgData.data[i + 2] = 255 - imgData.data[i + 2]; // Blue channel
        imgData.data[i + 3] = 255; // Alpha channel
      }

    resultCtx.putImageData(imgData, 0, 0);
    const fullBase64 = resultCanvas.toDataURL("image/jpeg", 1.0);
    let finalBase64 = fullBase64;
    const filteredBase64 = finalBase64.replace(/^data:image\/(png|jpeg|jpg);base64,/, "");
    return filteredBase64;
  }, []);


  const handleFinish = useCallback(async () => {

    //window.postMessage(JSON.stringify({extra:"handleFinish", data: generateResult()}), '*');
    // Call the API to get new image
  }, [generateResult]);

  const handleTouchEnd = useCallback(
    (e) => {
      e.preventDefault();
      handleFinish();


      const res = generateResult();
      window?.postMessage(JSON.stringify({ type: 'onDrawMask', payload: res , complete: didUserDrawRef.current}), '*');
      prevCoords.current.x = null;
      prevCoords.current.y = null;
    },
    [generateResult, handleFinish]
  );

  const onGetMask = () =>{
    const res = generateResult();
    window?.postMessage(JSON.stringify({ type: 'mask', payload: res , complete: didUserDrawRef.current, deleteMask: !didUserDrawRef.current}), '*');
  }

  useEffect(() => {
    document.addEventListener("message", (event) => {

      if (!event.data?.message) return;

      const { type, payload } = event.data?.message;

      if (type === "getMask") {
        onGetMask();
      } else if (type === "image") {
        setImage(payload);
      } else if(type === "sketchType"){
        setSketchType(payload);
      }
    });

    return () => {
      document.removeEventListener("message", () => { });
    }

  }, []);

  return (
    <div className="flex-col w-full bg-white">
      <div className={`flex justify-center items-center w-full h-full relative ${loading ? 'animate-pulse pointer-events-none' : ''}`}>
        <canvas
          ref={ canvas }
          onMouseMove={ handleMouseMove }
          onTouchMove={ handleTouchMove }
          onTouchEnd={ handleTouchEnd }
        />
        <canvas
          ref={ canvasOverlay }
          className="absolute  pointer-events-none"
        />
        <canvas
          ref={ drawingCanvas }
          className="absolute  pointer-events-none"
        />
      </div>
    {isSketch && !sketchType ? null :  <div className="flex justify-between px-5 items-center mt-5 space-x-4">
        <BrushSlider
        enablePencil={isSketch}
        max={ isSketch ? 1.5 : 20 }
        min={ isSketch ? 0.1 : 1 }
        value={ brushSize } onChange={ setBrushSize } />
        <UndoButton onClick={ onClear } />
      </div>}
    </div>
  );
};


function App() {
  // get original image id from query param "imageId"
  const searchParams = new URLSearchParams(window.location.search);
  const isRedraw = searchParams.get("redraw") === "true";
  const disableTexts = searchParams.get("disableTexts") === "true";
  const reverseMask = searchParams.get("reverseMask") === "true";
  const sketchType = searchParams.get("sketchType");
  const isSketch = searchParams.get("sketch") === "true";

  return (
    <div className="App flex-col w-full h-screen">
      <meta name="viewport" content="initial-scale=1.0, maximum-scale=1.0"></meta>
    {!disableTexts ? <div className='mb-4 px-4'>
        <h1 className="text-2xl font-bold text-black">{
       isRedraw ? "Edit Mask" : "Draw Mask"}</h1>
        <p className="text-black">Brush over the areas that you want to renovate, other areas will be preserved</p>
      </div> : null}
       <ImageBrushing
       isSketch={isSketch}
      initialSketchType={sketchType}
      reverseMask={reverseMask} />
    </div>
  );
}

export default App;
